import React from "react"
import { SectionType } from "../../types"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const SpacerSection = ({ section, index, ...props }: SectionProps) => (
  <div className={`w-full h-8 md:h-16 ${section.advanced?.class || ``}`} />
)

export default SpacerSection
